import React from "react"
import Seo from "../components/molecules/Seo"
import Splash from "../components/organisms/Splash"
import ContentHolder from "../components/organisms/ContentHolder"
import Image from "../components/atoms/Image"
import WorkTextBlock from "../components/molecules/WorkTextBlock"
import RelatedWork from "../components/organisms/RelatedWork"
import WorkImage from "../components/molecules/WorkImage"
import ArrowText from "../components/atoms/ArrowText"
import WorkMultipleImage from "../components/molecules/WorkMultipleImage"
import Numbers from "../components/organisms/Numbers"
import Number from "../components/molecules/Number"
import thumb from "../../static/images/nsw-health-1.png"

function NswHealth() {
  return (
    <>
      <Seo
        title="Reaching marginalised groups | Craig Walker x NSW Health"
        description="Craig Walker worked with KRC to develop and design a framework for reaching and meeting the needs of at-risk groups with the information, support and services they need."
        image={thumb}
      />
      <Splash
        text={<>Reaching and supporting marginalised&nbsp;groups</>}
        work={true}
      >
        <p>
          <b>
            In Australia, 130,000 people live with chronic hepatitis C and many
            more thousands don’t even know they’re infected. While hepatitis C
            can be treated, reinfection is a risk, especially among marginalised
            groups.
          </b>
        </p>

        <p>
          NSW Health South Eastern Sydney Local Health District’s Kirketon Road
          Centre (KRC) in Sydney is a world-leader in providing free,
          non-judgemental and anonymous health care for at-risk groups, such as
          sex workers and injecting drug users.
        </p>

        <p>
          With a goal to reduce blood-borne infections, KRC wanted to develop a
          way to provide long-term support to help its clients avoid hepatitis C
          reinfection.
        </p>

        <p>
          Craig Walker worked with KRC to develop and design a framework for
          reaching and meeting the needs of at-risk groups with the information,
          support and services they need.
        </p>
        <ArrowText>
          <p>Learn More</p>
        </ArrowText>
      </Splash>

      <ContentHolder>
        <WorkImage>
          <Image imgName="nsw-health-new-3.jpg" />
        </WorkImage>

        <Numbers>
          <Number
            number={12}
            text="Health professional/&#8203;academic interviews"
          />
          <Number number={`30+`} text="Ideas generated" />
          <Number number={4} text="Service models designed and delivered" />
        </Numbers>

        <WorkMultipleImage
          images={["nsw-health-1.png", "nsw-health-2.png", "nsw-health-3.png"]}
        />

        <WorkTextBlock>
          <h2>Healthy collaboration</h2>
          <p>
            In a fast-paced, three-week engagement, we co-designed a framework
            for long-term support with the KRC team, doctors, nurses, academic
            and service partners as well as KRC clients with the experience of
            living with hepatitis C.
          </p>

          <p>
            The support framework not only had to deliver health care services,
            it had to meet the needs and fit the circumstances of at-risk
            clients.
          </p>
        </WorkTextBlock>

        <WorkImage>
          <Image imgName="nsw-health-new-2.jpg" />
        </WorkImage>

        <WorkTextBlock>
          <h2>Four steps to Hep-C&nbsp;free</h2>
          <p>
            The framework details four interventions for KRC: increase awareness
            among at-risk clients; maintain contact to support clients with a
            holistic care plan; encourage clients coming out of prison to get in
            touch; and to celebrate and incentivise them to stay on track.
          </p>
        </WorkTextBlock>

        <WorkImage>
          <Image imgName="nsw-health-new-1.jpg" />
        </WorkImage>

        <RelatedWork exclude="nsw-health" />
      </ContentHolder>
    </>
  )
}

export default NswHealth
